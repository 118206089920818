.card {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 20;
  background-color: #1b1b1d;
}

.card::before {
  content: "";
  position: absolute;
  top: var(--y);
  left: var(--x);
  transform: translate(-50%, -50%);
  background: radial-gradient(#d775ff, transparent, transparent);
  width: 300px;
  height: 300px;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 22;
}

.card:hover::before {
  opacity: 0.4;
}

.card::after {
  content: "";
  position: absolute;
  inset: 2px;
  border-radius: 18px;
  background: transparent;
}

.cardAbove {
  z-index: 10;
  background: 1b1b1d;
  /* border: 1px solid #0b0b0b; */
  border-radius: 8px;
}

/* .cardAbove:hover {
  background-image: linear-gradient(90.61deg, #d775ff 0.53%, #79f8ff 111.46%);
} */
