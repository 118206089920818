@font-face {
  font-family: "AlataRegular";
  src: url('./fonts/Alata-Regular.ttf') format('truetype');
}

.rpg-font {
  font-family: "AlataRegular";
}

.conet-bg {
  background-image: url("./assets/background.png");
}

.buildings-bg {
  background-image: url("./assets/floor-and-buildings.png");
}

.black-stroke h1, .black-stroke h2, .black-stroke p {
  color: #ffffff;
  text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
}

.modal-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  cursor: pointer;
}

.modal-container {
  position: relative;
  width: 85vw;
  max-width: 900px;
  min-height: 500px;
  background-size: cover;
  border-radius: 20px;
  padding: 2rem 3rem;
  cursor: default;
  display: flex;
  align-items: center;
}

.modal-content {
  display: flex;
  align-items: center;
  gap: 2rem;
  z-index: 250;
}

.modal-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  text-align: center;
}

.modal-title {
  margin-bottom: 0.25rem;
  font-size: 3rem;
}

.modal-subtitle {
  font-size: 2rem;
}

.modal-description {
  font-size: 1.5rem;
  max-width: 450px;
}

.modal-button {
  all: unset;
  color: black;
  font-size: 1.5rem;
  background-color: #79F8FF;
  width: 230px;
  height: 52px;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.mobile-modal-image {
  width: 130px;
  display: block;
}
.modal-image-container {
  display: none;
}

@media (min-width: 768px) {
  .modal-image-container {
    display: block;
  }

  .mobile-modal-image {
    display: none;
  }

  .modal-text {
    gap: 3rem;
  }

  .modal-container {
    padding: 3rem 4rem;
  }
}

.modal-close-button {
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  width: 40px;
  height: 40px;
  background-color: #8DA8FF;
  color: white;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.modal-background {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: right;
  border-radius: 20px;
}

